import { Component, OnInit, OnDestroy } from '@angular/core';
import { Tour } from '../../models/tour.model';
import { TourService } from '../../services/tour.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.css']
})
export class TimetableComponent implements OnInit, OnDestroy {
  mayToursSub: Subscription;
  mayTours: Tour[];
  juneToursSub: Subscription;
  juneTours: Tour[];
  julyToursSub: Subscription;
  julyTours: Tour[];
  augustToursSub: Subscription;
  augustTours: Tour[];
  septemberToursSub: Subscription;
  septemberTours: Tour[];

  constructor(private tourService: TourService) { }

  ngOnInit() {
    this.mayToursSub = this.tourService.getPublicToursByMonth(5).subscribe(tours => {
      this.mayTours = tours;
    });

    this.juneToursSub = this.tourService.getPublicToursByMonth(6).subscribe(tours => {
      this.juneTours = tours;
    });

    this.julyToursSub = this.tourService.getPublicToursByMonth(7).subscribe(tours => {
      this.julyTours = tours;
    });

    this.augustToursSub = this.tourService.getPublicToursByMonth(8).subscribe(tours => {
      this.augustTours = tours;
    });

    this.septemberToursSub = this.tourService.getPublicToursByMonth(9).subscribe(tours => {
      this.septemberTours = tours;
    });
  }

  ngOnDestroy() {
    this.mayToursSub.unsubscribe();
    this.juneToursSub.unsubscribe();
    this.julyToursSub.unsubscribe();
    this.augustToursSub.unsubscribe();
    this.septemberToursSub.unsubscribe();
  }
}
