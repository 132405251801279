import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HeaderComponent } from './main/components/header/header.component';
import { NavigationComponent } from './main/components/navigation/navigation.component';
import { FooterComponent } from './main/components/footer/footer.component';
import { HomeComponent } from './main/components/home/home.component';
import { TimetableComponent } from './tours/components/timetable/timetable.component';
import { TourComponent } from './tours/components/tour/tour.component';
import { TourMonthComponent } from './tours/components/tour-month/tour-month.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    HomeComponent,
    TimetableComponent,
    TourComponent,
    TourMonthComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
