import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Tour } from '../models/tour.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TourService {

  constructor(private http: HttpClient) { }

  getPublicToursByMonth(month: number) {
    return this.http.get<Tour[]>(environment.api + '/tours/month/' + month);
  }
}
