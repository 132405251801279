import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  @Output() toggleNavSlide: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  linkClicked() {
    this.toggleNavSlide.emit();
  }

}
