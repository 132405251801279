import { Component, OnInit, Input } from '@angular/core';
import { Tour } from '../../models/tour.model';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css']
})
export class TourComponent implements OnInit {
  @Input()
  tour: Tour;

  constructor() { }

  ngOnInit() { }

}
