import { Component, OnInit, Input } from '@angular/core';
import { Tour } from '../../models/tour.model';

@Component({
  selector: 'app-tour-month',
  templateUrl: './tour-month.component.html',
  styleUrls: ['./tour-month.component.css']
})
export class TourMonthComponent implements OnInit {
  @Input()
  tours: Tour[];

  constructor() { }

  ngOnInit() { }

  setTourClasses(tour: Tour) {
    let classes = {
      isHidden: tour.hidden,
      isCancelled: tour.cancelled
    }

    return classes;
  }
}
